import TextLink from '@mablemarket/common-react-lib/build/components/TextLink';
import { AdminDashboardItem } from '@mablemarket/core-api-client';
import { Heading } from '@mablemarket/common-react-lib/build/components';

import Card from '../Card';

import styles from './Tile.module.scss';

export const Tile = (props: AdminDashboardItem) => {
  const { title, value, destination } = props;
  return (
    <Card title={title} destination={destination}>
      <TextLink display='inline' href={destination} className={styles.displayTextLink}>
        <Heading paddingBottom='space-1' level={3} className={styles.displayHeading}>
          {new Intl.NumberFormat('en-us').format(value)}
        </Heading>
      </TextLink>
    </Card>
  )
};
