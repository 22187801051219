import { Redirect } from '@mablemarket/common-react-lib/build/modules/NextRouter/components';
import { UserHas } from '../Auth0Provider';
import { withAdminLayout } from '../helpers/withAdminLayout';
import { Dashboard } from '../screens/Dashboard';

const WrappedDashboard = () => {
  return (
    <UserHas permission="admin" else={<Redirect to="/sellers" />}>
      <Dashboard />
    </UserHas>
  );
};

export default withAdminLayout(WrappedDashboard, { bootstrap: true });
