import React from 'react';
import { useState } from 'react';
import { AdminDashboard as DashboardType } from '@mablemarket/core-api-client';
import { useAsyncEffect } from '../../helpers/CustomHooks';
import { Tile } from '../../components/Tile';
import styles from './Dashboard.module.scss';
import { useApiClient } from '@mablemarket/common-react-lib';
import Head from 'next/head';

export const Dashboard: React.FC = () => {
  const client = useApiClient();
  const [dashboard, setDashboard] = useState<DashboardType>();

  useAsyncEffect(async () => {
    const res = await client.req('GET /v1/admin/dashboard', {});
    setDashboard(res.body);
  }, []);

  if (!dashboard) { return <></> };

  const tileCards = dashboard.tiles.map((tile, idx) => {
    const { title, value, destination } = tile;
    return <Tile
      key={idx}
      value={value}
      title={title}
      destination={destination}
    />;
  });

  return <React.Fragment>
    <Head>
      <title>admin-web</title>
    </Head>
    <div className={styles.dashboardGrid}>
      {tileCards}
    </div>
  </React.Fragment>;
}

