import React from 'react';
import { Heading } from '@mablemarket/common-react-lib/build/components';
import TextLink from '@mablemarket/common-react-lib/build/components/TextLink';
import cn from "classnames";
import styles from "./Card.module.scss";

interface CardProps {
  children: any;
  title?: string;
  destination?: string;
}

const Card: React.FC<CardProps> = (props) => {
  const { children, title, destination } = props;
  const classes = cn(styles.card);
  const titleElem = <Heading level={4}>{title}</Heading>

  return (
    <div className={classes}>
      {title &&
        <header className={styles.cardHeader}>
          {destination
            ? (
              <TextLink display='inline' href={destination}>
                {titleElem}
              </TextLink>
            )
            : (
              titleElem
            )
          }

        </header>
      }
      <div className={styles.cardContent}>
        {children}
      </div>
    </div>
  )
}

export default Card;
